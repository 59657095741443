<template>
  <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quae quas dolorem, sed unde autem suscipit deserunt voluptatum voluptate numquam dignissimos reprehenderit earum, labore architecto doloribus sit ipsa odit vel! -->
  <div>
    <Navbar />

    <div class="px-4 lg:px-2 py-8 2xl:py-10 md:px-12 flex-1 container mx-auto">
      <h1 class="text-2xl 2xl:text-4xl font-semibold mb-4">Privacy Policy</h1>

      <div class="grid 2xl:grid-cols-2 gap-10">
        <section>
          {{ privacyText }}
          {{ labels }}
        </section>

        <section class="mt-10">
          <h2 class="text-2xl font-semibold mb-4">Crea Label</h2>

          <form class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <label for="key">Nome</label>
              <input
                type="text"
                id="key"
                name="key"
                class="border-none rounded text-gray-900"
                v-model="key"
              />
            </div>

            <div>
              <textarea
                name="value"
                id="value"
                class="border-none rounded w-full h-96 text-gray-900"
                v-model="value"
              ></textarea>
            </div>

            <button
              @click.prevent="createLabel"
              type="submit"
              class="btn btn--red self-start"
            >
              Crea
            </button>
          </form>

          {{ key }}
          {{ value }}

          <button class="btn btn--red" @click="deleteLabel">
            Elimina label
          </button>
        </section>

        <section class="mt-10">
          <h2 class="text-2xl font-semibold mb-4">Modifica Label</h2>

          <form class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <label for="key">Nome</label>
              <input
                type="text"
                id="key"
                name="key"
                class="border-none rounded text-gray-900"
                v-model="key"
              />
            </div>

            <div>
              <textarea
                name="value"
                id="value"
                class="border-none rounded w-full h-96 text-gray-900"
                v-model="value"
              ></textarea>
            </div>

            <button
              @click.prevent="editLabel"
              type="submit"
              class="btn btn--red self-start"
            >
              Modifica
            </button>
          </form>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { getClient } from "@/includes/api";

import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: { Navbar, Footer },
  data() {
    return {
      privacyText: "",
      key: null,
      value: null,
      labels: null,
    };
  },
  async mounted() {
    try {
      let apiClient = getClient();
      let labels = await apiClient.getLabels();
      this.labels = labels;
      let privacy = labels.filter((label) => label.key === "privacy_policy");
      this.privacyText = privacy[0].value;
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    async createLabel() {
      try {
        let key = this.key;
        let value = this.value;
        console.log(key, value);
        let apiClient = getClient();
        debugger;
        let labelCreated = await apiClient.setLabel(key, value);
        debugger;
        this.labels.push({
          key: key,
          value: value,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async editLabel() {
      try {
        let key = this.key;
        let value = this.value;
        let apiClient = getClient();
        let labelEdited = await apiClient.editLabel(key, value);
        let newLabels = [];
        this.labels.forEach((label) => {
          if (label.key !== key) {
            newLabels.push(label);
          }
        });
        this.labels = newLabels;

        this.labels.push({
          key: key,
          value: value,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async deleteLabel() {
      try {
        let key = this.key;
        let apiClient = getClient();
        debugger;
        let labelDeleted = await apiClient.deleteLabel(key);
        debugger;
        let newLabels = [];
        this.labels.forEach((label) => {
          if (label.key !== key) {
            newLabels.push(label);
          }
        });
        this.labels = newLabels;
        console.log(labelDeleted);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style></style>
